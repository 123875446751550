.App {
  background-image: url("../assets/background-med.webp");
  height: 100vh;
  border: 6px solid white;
  border-bottom: none;
  background-size: cover;
}

/*Large Screens*/
@media screen and (min-width: 1300px) {
  .App {
    background-image: url("../assets/background.webp");
  }
}

.page-text {
  line-height: 1.5;
  font-size: 1.2rem;
  margin-top: 3rem;
}

.page {
  text-align: center;
  position: absolute;
  top: 40%;
  width: 100vw;
}

button {
  background: none;
  border: none;
  color: black;
}

.hidden {
  display: none;
  transition: 1s;
}

.square {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 16rem;
  height: 16rem;
  background-color: rgb(255, 255, 255, 0.3);
  padding: 4rem 2rem 0rem;
  transition: all 0.3s linear;
}

@media only screen and (max-width: 650px) {
  .hamburger {
    position: absolute !important;
    z-index: 1000;
    top: 40px;
    right: 40px;
    width: 5rem;
    height: 5rem;
    transition: transform 0.5s ease;
  }

  .App .square {
    width: 100vw;
  }

  .link {
    font-size: 1.4rem;
  }

  .square {
    width: 10rem;
    height: 10rem;
    padding: 1rem 2rem 3rem;
  }

  .navbar {
    position: absolute;
    top: 0;
    left: 0;
    background-color: rgb(0, 0, 0, 0.95);
    width: 100vw;
    height: 100vh;
    overflow: hidden;
    /* hide the menu above the screen by default */
    /* transition adds a little animation when sliding in and out the menu */
    transition: transform 0.5s ease;
    transform: translateY(-100%);
    z-index: 3;
    display: block;
    display: grid;
    padding-top: 7rem;
    grid-template-rows: 3.5rem 3.5rem 3.5rem 3.5rem 3.5rem 3.5rem 3.5rem 3.5rem;
    text-align: center;
  }

  .navbar button,
  .navbar a {
    color: white;
  }

  .navbar .active, .navbar :hover {
    border: 0px !important;
    color: grey !important;
  }

  .showNav {
    /* show the menu */
    transform: translateY(0);
  }

  #contact-area .page .square {
    width: 100vw;
    height: 60vh;
    top: 55%;
    padding: 1rem 2rem 2rem;
  }
}
