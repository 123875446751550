:root {
  --title-font: Playfair, serif;
  --secondary-font: "Inter", sans-serif;
  --clear: hsla(0, 0%, 100%, 0.1);
}

@font-face {
  font-family: Playfair;
  font-weight: 600;
  src: url(assets/PlayfairDisplay-Italic-VariableFont_wght.ttf);
}

.font-playfair {
  font-family: var(--title-font);
}

a,
button {
  cursor: pointer;
}

html,
body {
  margin: 0;
  font-family: var(--secondary-font);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
  overflow-y: hidden;
}

a {
  color: black;
  text-decoration: none;
}
