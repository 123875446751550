#header {
  width: 100vw;
  height: 15vh;
}

#signature {
  position: relative;
  float: left;
  width: 100px;
  z-index: 1000;
  margin: 10px 30px;
}

.link {
  margin: 2rem 0.5rem;
  font-size: 1rem;
  float: left;
  color: black;
  text-decoration: none;
  padding: 0px 10px;
}

a,
.link {
  border-bottom: 3px solid rgb(0, 0, 0, 0);
}

a:hover,
.link:hover {
  border-bottom: 3px solid rgb(255, 255, 255, 0.1);
}

.navbar .active {
  border-bottom: 3px solid rgb(255, 255, 255, 0.3) !important;
}

.inverted {
  filter: invert(1) !important;
}

@media (min-width: 651px) {
  .hamburger {
    display: none !important;
  }
}
